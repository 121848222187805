import React, { useEffect, useRef, useState } from 'react';
import css from './RoleForm.module.css';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import classNames from 'classnames';
import { Form, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import * as validators from '../../util/validators';
import { Button, FieldCheckbox, FieldCheckboxGroup, FieldSelect, PrimaryButton } from '..';
import { apiBaseUrl } from '../../util/api';


const RoleFormComponent = props => (
    <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        currentUser,
       
        handleSubmit,
        inProgress,
        saveInput,
        handleTextChange,
        createListingInProgress,
        
      } = fieldRenderProps;
      
   


      return (
             <>
             <form onSubmit={handleSubmit}>
                    <div className={css.roleForm}>
                    <div className={css.formCon}>
                        
                            <label className={css.labels}>Select your role</label>
                            <select className={css.selectControl} id="role" name="role" label="role"
                            onChange={handleTextChange}
                            >
                                <option value="">- Select Role-</option>
                                <option value="Customer">Customer</option>
                                <option value="Vendor">Vendor</option>
                            </select>
                        
                            <Button className={css.submitBtn} type="submit" inProgress={createListingInProgress}>
                                SAVE
                            </Button>
                        
                            </div>
                    </div>
                </form>

             </>
        
      );
    }}
  />
  )
  
  const RoleForm = compose(injectIntl)(RoleFormComponent);
  RoleForm.displayName = 'RoleFormComponent';
  
  export default RoleForm;
  