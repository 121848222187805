import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { bool, object, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';
import Cookies from 'js-cookie';

import FallbackPage from './FallbackPage';
import { ASSET_NAME, getAuthorData } from './LandingPage.duck';
import { authenticationInProgress } from '../../ducks/auth.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { injectIntl } from 'react-intl';
import { useConfiguration } from '../../context/configurationContext';
import { ensureCurrentUser } from '../../util/data';
import { Button, NamedRedirect } from '../../components';
import { createCart, createWishlist, onGetListingsDetails, removeCartItem } from '../ListingPage/ListingPage.duck';
import { forEach } from 'lodash';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import RoleForm from '../../components/CustomSection/RoleForm';
import { updateUserRole } from '../EditListingPage/EditListingPage.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);



const getAuthInfoFromCookies = () => {
  return Cookies.get('st-authinfo')
    ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
    : null;
};
const getAuthErrorFromCookies = () => {
  return Cookies.get('st-autherror')
    ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
    : null;
};


export const LandingPageComponent = props => {

  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [authInfo, setAuthInfo] = useState(getAuthInfoFromCookies());
  const [authError, setAuthError] = useState(getAuthErrorFromCookies());
  const [roleInput, saveInput] = useState("");
  const config = useConfiguration();


  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
    }

   
  }, []);

  // On mobile, it's better to scroll to top.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tosModalOpen, privacyModalOpen]);

  const { 

    currentUser,
    isAuthenticated,
    location,
    showFacebookLogin,
    showGoogleLogin,
    loginError,
    signupError,
    authInProgress,
    submitSignup,
    termsAndConditions,
    tab,
    pageAssetsData, 
    inProgress, 
    error ,
    entities, 
    submitLogin,
    onCreateCart,
    onRemoveCartItem,
    cartData,
    quantity,
    prices,
    addToWishlist,
    wishlistId,
    createWishlistInProgress,
    createWishlistError,
    getAuthor,
    onUpdateUserRole,
    createListingInProgress,
    cartDataDetails,
    loadCartDetailsInProgress,
    loadCartDetailsInSuccess,
    onGetListingsDetails
  } = props;

  useEffect(() => {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    if (authError) {
      Cookies.remove('st-autherror');
    }

    if(cartData !== undefined && cartData.length > 0){
      if(cartDataDetails === undefined || cartDataDetails.length === 0 || !loadCartDetailsInSuccess || cartData.length > cartDataDetails.length){
        //Get listing details and authors
        onGetListingsDetails(cartData);
      }
    }

    
  }, [loadCartDetailsInSuccess]);

  
 // History API has potentially state tied to this route
  // We have used that state to store previous URL ("from"),
  // so that use can be redirected back to that page after authentication.
  const locationFrom = location.state?.from || null;
  const authinfoFrom = authInfo?.from || null;
  const from = locationFrom ? locationFrom : authinfoFrom ? authinfoFrom : null;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;
  const isLogin = tab === 'login';
  const role = currentUser !== undefined? currentUser?.attributes?.profile?.protectedData?.role:"";
  const roleNotSet = (role === undefined || role === null) && isAuthenticated;

  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;
  const createCart = (data)=>{
    console.log(JSON.stringify(data));
    onCreateCart(data);

  }

  const removeCartItem =(id)=>{
    onRemoveCartItem(id);
  }


  // //console.log(from + "        33333333333333333333333333333333");
  // // Already authenticated, redirect away from auth page
  // if (isAuthenticated && from) {
  //   console.log("Is Auth   " + from);
  //   return <Redirect to={from} />;
  // } else if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
  //   console.log("Is Not Auth   " + from);
  //   return <NamedRedirect name="LandingPage" />;
  // }

  const handleTextChange = (event)=>{
    saveInput(event.target.value);
    console.log(roleInput);
  }

  const handleSubmit = (event)=>{
    console.log(roleInput);
    onUpdateUserRole(roleInput);
  }

  return (

    <>

        {roleNotSet !== undefined && roleNotSet? 
          <RoleForm onSubmit={handleSubmit} saveInput={saveInput} handleTextChange={handleTextChange} createListingInProgress={createListingInProgress}/>
        :""}
      

        <PageBuilder
          pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
          inProgress={inProgress}
          error={error}
          entities={entities}
          submitLogin={submitLogin}
          authInProgress={authInProgress}
          isAuthenticated={isAuthenticated}
          fallbackPage={<FallbackPage error={error} />}
          createCart={createCart}
          removeCartItem={removeCartItem}
          cartData={cartData}
          cartDataDetails={cartDataDetails}
          quantity={quantity}
          prices={prices}
          addToWishlist={addToWishlist}
          wishlistId={wishlistId}
          createWishlistInProgress={createWishlistInProgress}
          createWishlistError={createWishlistError}
          getAuthor={getAuthor}
          currentUser={currentUser}
        />

    </>
  );
};


LandingPageComponent.defaultProps = {
  
  tab: 'signup',
  
};



LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
  tab: oneOf(['login', 'signup', 'confirm']),
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
          pageAssetsData: privacyAssetsData,
          inProgress: privacyFetchInProgress,
          error: privacyFetchError,
        } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } = state.hostedAssets || {};
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const {entities} = state.marketplaceData;
  let {
    cartData,
    createWishlistInProgress,
    createWishlistError,
    cartDataDetails,
    loadCartDetailsInProgress,
    loadCartDetailsInSuccess
  } = state.ListingPage;
  const wishlist = currentUser?.attributes?.profile?.privateData?.wishlist;
  const wishlistId = [];

  if(wishlist !== undefined && wishlist.length !== 0){
    wishlist.map((val,key)=>{
      wishlistId.push(val.id);
    });
  }
    
  const currentUserCart = currentUser?.attributes?.profile?.privateData?.cartData;
  cartData = cartData.length !== 0?cartData:currentUserCart;
  let quantity = 0;
  let prices = 0;

  if(cartData !== undefined && cartData.length !== 0 ){
    cartData.filter((item)=>{
    quantity += parseInt(item.quantity);
    prices += parseInt(item.price);
  });
  }


  const {createListingInProgress} = state.EditListingPage;

  

  return { 
      authInProgress: authenticationInProgress(state),
      currentUser,
      isAuthenticated,
      loginError,
      scrollingDisabled: isScrollingDisabled(state),
      signupError,
      confirmError,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      privacyAssetsData,
      privacyFetchInProgress,
      privacyFetchError,
      tosAssetsData,
      tosFetchInProgress,
      tosFetchError,
      pageAssetsData,
      inProgress,
      error ,
      entities,
      cartData,
      cartDataDetails,
      loadCartDetailsInProgress,
      loadCartDetailsInSuccess,
      quantity,
      prices,
      wishlistId,
      createWishlistInProgress,
      createWishlistError,
      createListingInProgress,
    };
};


const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }) => dispatch(login(email, password)),
  submitSignup: params => dispatch(signup(params)),
  submitSingupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onCreateCart: (cartData) =>dispatch(createCart(cartData)),
  onRemoveCartItem: (id) =>dispatch(removeCartItem(id)),
  addToWishlist: (data) =>dispatch(createWishlist(data)),
  getAuthor: (authorId) =>dispatch(getAuthorData(authorId)),
  onUpdateUserRole: role => dispatch(updateUserRole(role)),
  onGetListingsDetails: (data)=> dispatch(onGetListingsDetails(data))
  
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;

